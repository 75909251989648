"use client";

import { assertNotNullish } from "./assertNotNullish";
import { createContext, useContext } from "react";
import { type Translations } from "./data/getTranslations";

const TranslationsContext = createContext<Translations | undefined>(undefined);

export const TranslationsContextProvider = (
  args: React.ComponentProps<typeof TranslationsContext.Provider>,
) => <TranslationsContext.Provider {...args} />;

export const useTranslationsContext = () => {
  const context = useContext(TranslationsContext);

  assertNotNullish(
    context,
    "useTranslationsContext was used outside of Provider",
  );

  return context;
};
